<template>
  <b-container
    class="bg-light text-white pb-0 fontStandard wall"
    :style="this['events/handleMinHeight']"
    fluid
  >
    <NavTabs :tabs="tabs" :customs="navTabs">
      <b-container class="bg-blue" :style="handleMinHeight" fluid>
        <transition name="smoothly-transition" mode="out-in">
          <!-- Components Tabs -->
          <Dashboard v-if="canISeeThisTab('dashboard')" />
          <AccountList v-else-if="canISeeThisTab('account_list')" />
          <HorseList v-else-if="canISeeThisTab('horse_list')" />
          <HorseAdd v-else-if="canISeeThisTab('horse_add')" />
          <HealthReminder v-else-if="canISeeThisTab('health_reminder')" />
          <View403 v-else />
          <!-- Components Tabs -->
        </transition>
      </b-container>
    </NavTabs>
  </b-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Tabs from "./Admin/Tabs";

export default {
  mixins: [Tabs],
  components: {
    NavTabs: () => import("@/components/NavTabs"),
  },
  computed: {
    ...mapState(["axios", "events"]),
    ...mapGetters(["events/handleMinHeight"]),
    handleMinHeight() {
      const height = this.events.height - 150;
      return {
        "min-height": parseInt(height) + "px",
      };
    },
    currentTab() {
      return this.$route.params.tab;
    },
    navTabs() {
      return {
        activeBgColor: "#163a58",
        activeColor: "#ffc107",
        inactiveBgColor: "#0c2030",
        inactiveColor: "white",
        borderColor: "#343434",
        hoverColor: "#163a58",
      };
    },
  },
  mounted() {
    this.$root.timer_loop();
  },
  beforeDestroy() {
    clearTimeout(this.timer_state);
    this.$root.renewToken();
  },
};
</script>

