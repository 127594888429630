import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  components: {
    Dashboard: () => import("./Dashboard"),
    AccountList: () => import("./AccountList"),
    View403: () => import("@/components/View403"),
  },
  computed: {
    ...mapState(["account", "events"]),
    ...mapGetters(["admin/allow_this_tab", "admin/allow_this_action"]),
    handleMinHeight() {
      const height = this.events.height - 180;
      return {
        "min-height": parseInt(height) + "px",
      };
    },
  },
  data() {
    return {
      toto: 1234,
      tabs: [
        { title: "Tableau de bord", to: "dashboard" },
        { title: "Liste des comptes", to: "account_list" },
        // { title: "Liste des chevaux", to: "horse_list" },
        // { title: "Ajouter un cheval", to: "horse_add" },
      ],

      timeout_state1: null,
    };
  },
  mounted() {
    this.$store.dispatch("admin/save_admin_rights", { vm: this });
    clearTimeout(this.timeout_state1);
    this.$root.timer_pause = true;
    this.checkAdminRights();
  },
  beforeDestroy() {
    clearTimeout(this.timeout_state1);
    this.$root.timer_pause = false;
    this.timeout_state1 = this.tabs = this.tabsAllow = this.actionsAllow = undefined;
  },
  methods: {
    checkAdminRights() {
      clearTimeout(this.timeout_state1);
      this.timeout_state1 = setTimeout(() => {
        this.$store.dispatch("admin/save_admin_rights", { vm: this });
        this.checkAdminRights();
      }, 1000); // TIMER A 1000
    },
    canISeeThisTab(tab = "") {
      return this.currentTab === tab && this["admin/allow_this_tab"](tab)
        ? true
        : false;
    },
  },
};
